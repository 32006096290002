.about__wrapper{
    display: flex;
    column-gap: 2rem;
    margin-top: 200px;
}

.about__content,.about__img{
    width: 2000px;
}




.about__img img{
    width: 100%;
    height: 100%;
    object-fit:fill;
    border-radius: 0.5rem;
}

.description__about__content-desc {
    display: flex;
    margin-top: 10px;
    line-height: 1.8;
    text-align: justify;
}


.choose__item-wrapper{
    margin-top: 20px;
}

.choose__us-item_1{
    padding: 20px 20px 20px 0px;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 15px;
}

.choose__us-icon{
    width: 55px;
    height: 110px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
}

.choose__us-icon i{
    color: var(--primary-color);
    font-size: 3rem;
}

.choose_us-title_1 {
    color: #000000;
    font-weight: 500;
    font-size: 1rem;
}

.choose_us-title_1 p {
    font-weight: 400;

}

.description__about_1{
    margin-top: 10px;
    color: #050101
}

.about_learn_button{
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.about_btn{
    padding: 0.1rem 1rem;
    outline: none;
    color: #fff;
    border-radius: 0.9rem;
    cursor: pointer;
    font-weight:bold;
    font-family:Georgia, 'Times New Roman', Times, serif;
}

.about_btn{
    background: var(--btn-primary-bg);
    border: 10px solid var(--btn-primary-bg);
}


@media only screen and (max-width:810px){
    .subtitle{
        font-size: 1.5rem;
    }
    .description__about__content-desc{
        font-size: 0.8rem;
    }
    .choose_us-title_1 {
        color: #000000;
        font-weight: 500;
        font-size: 0.8rem;
        font-weight: bolder;
    }
    p{
        font-size: 0.7rem;
    }
    
    .about_learn_button{
        margin-top: 10px;
    }
    
}

@media only screen and (max-width:480px){
    .container{
        width: 100%;
    }
    .subtitle{
        font-size: 1rem;
    }
    
    

    .description__about__content-desc{
        font-size: 0.57rem;
        display: flex;
        margin-bottom: -20px;
    }
    
    .choose_us-title_1 {
        margin-top: 20px;
        color: #000000;
        font-weight: 500;
        font-size: 1rem;
        font-weight: bolder;
    }
    p{
        font-size: 1rem;
        
    }
    
    .about_learn_button{
        margin-top: 10px;
    }

    .about_btn{
        font-size: 0.57rem;
    }
    
    .about__img {
       height: 410px;
    }
    
   
    
    .choose__us-icon{
        margin-top: 25px;
    }
    
    .choose_us-title_1 {
        color: #000000;
        font-weight: 500;
        font-size: 0.8rem;
        width: 300%;
    }
    
    .description__about_1{
        margin-top: 10px;
        color: #050101;
        font-size: 0.57rem;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }
    
    
}

@media only screen and (min-width: 481px) and (max-width: 800px) {
    .container{
        width: 100%;

    }
    .subtitle{
        font-size: 1rem;
    }
    .description__about__content-desc{
        font-size: 0.57rem;
        display: flex;
        margin-bottom: -20px;
    }
    .choose_us-title_1 {
        margin-top: 30px;
        color: #000000;
        font-weight: 500;
        font-size: 1rem;
        font-weight: bolder;
    }
    p{
        font-size: 1rem;
        
    }
    
    .about_learn_button{
        margin-top: 10px;
    }

    .about_btn{
        font-size: 0.57rem;
    }
    
    .about__img {
       height: 350px;
       width: 100px;
    }
    
   
    
    .choose__us-icon{
        margin-top: 25px;
    }
    
    .choose_us-title_1 {
        color: #000000;
        font-weight: 500;
        font-size: 0.8rem;
        width: 300%;
    }
    
    .description__about_1{
        margin-top: 10px;
        color: #050101;
        font-size: 0.57rem;
    }
    
}