.button {
    display: inline-block;
    position: relative;
    padding: 10px 16px;
    border-radius: 50px;
    background-color:  #0c123d;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    margin-bottom: -30px;
    border: none; /* This removes the border */
    float: left; /* Align button to the left */
    margin-right: 200px; /* Adjust margin for spacing */
    margin-top: 20px; /* Adjust margin for spacing */

}
  
  .button:hover {
    background-color: rgb(84,196,148);
    color: #fff;
  }
  
  