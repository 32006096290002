.counter{
    margin-top: 30px;
    padding: 10px;
    width: 100%;
    height: 140px;
    background-color: rgb(84,196,148);
}

.counter__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.counter__item {
    widows: 33px;
    text-align: center;
}

.counter__number,
.counter__title{
    background: var(--counter-color);
    background-size: 100% 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}



.counter__number{
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.counter__title {
    font-size: 1.5rem;
}


@media only screen and (max-width:992px){
    .counter{
        width:100% ;
    }
}

@media only screen and (max-width:810px){
    .counter{
        width:100% ;
        height: 50%;
    }

    .counter__number{
        margin-top: -20px;
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

}

@media only screen and (max-width:480px){
    .counter{
        width:100% ;
        height: 50%;
    } 
    /* .container{
        width: 100% ;
     } */

     .counter__number{
        font-size: 0.8rem;
        margin-bottom: 10px;
        margin-left: -30px;
        padding-inline: 20px;
    }
    
    .counter__title {
        font-size: 0.55rem;
        margin-left: -30px;
        padding-inline: 10px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 800px) {
    .counter{
        width:100% ;
        height: 50%;
    } 
    /* .container{
        width: 100% ;
     } */

     .counter__number{
        font-size: 0.8rem;
        margin-bottom: 10px;
        margin-left: -30px;
        padding-inline: 20px;
    }
    
    .counter__title {
        font-size: 0.55rem;
        margin-left: -30px;
        padding-inline: 10px;
    }
}

