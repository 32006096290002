.hero__wrapper{
    display: flex;
    justify-content: space-between;
}

.hero__img{
    display: flex;
    width: -0.5%;   
}

.hero__content{
    width: 60%;
    padding-top: 140px;
}

.hero__content h2 {
    font-size: 3rem;
    line-height: 65px;
}

.hero__content h3 {
    font-size: 1.7rem;
    margin-top: 20px;
}

.hero__content p{
    margin-top: 20px;
    font-size: 1.1rem;
}


.highlight {
    color: var(--primary-color);
}

.hero__btns{
    margin-top: 30px;
    display: flex;
    align-items: center;
    column-gap: 1.8rem;
}

.primary__btn,
.secondary__btn{
    margin-top: 30px;
    padding: 0.6rem 1.5rem;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 0.9rem;
    cursor: pointer;
    font-weight:bolder;
    font-family:Georgia, 'Times New Roman', Times, serif;
}

.primary__btn{
    background: var(--btn-secondary-bg);
    border: 1px solid var(--btn-primary-bg);
}

.secondary__btn{
    background: var(--btn-primary-bg);
}

@media only screen and (max-width:992px){
    .hero__content h2{
        font-size: 1.8rem;
        line-height: 40px;
    }

    .hero__img{
        width: 70%;
    }
}

@media only screen and (max-width:810px){
    .container{
        width: 100%;
        height: auto;
    }
    
    
    .hero__content h3 {
        font-size: 1.3rem;
    } 
    
    .hero__content h2{
    font-size: 0.rem;
    line-height: 1.2;
    margin-bottom: 20px;
    overflow: hidden; /* Prevents text from overflowing */
    text-overflow: ellipsis; /* Adds an ellipsis when the text overflows */
}

    .hero__btns{
        column-gap: 2rem;
    }

    
    .hero__img{
        height: 400px;
        width: 400px;
    }
    
    .primary__btn,
    .secondary__btn{
        padding: 0.2rem 1.2rem;
    }
}


@media only screen and (max-width:480px){
    .container{
        width: 100%;
        height: auto;
    }
    
    .hero__content h3 {
        font-size: 0.65rem; /* Increased the font size */
        margin-top: 20px;
    } 
    
    .hero__content h2{
        font-size: 0.6rem; /* Increased the font size */
        line-height: 1.2; /* Adjusted line-height */
        margin-bottom: 20px;
      
    }

     .hero__btns{
        column-gap: 0.6rem;
        margin-top: -30px;
    }

    .hero__img{
        height: 150px;
        width: 100%; /* The image will take the full width of its container */       
        margin-top: 90px;
        margin-left: 30px;
    }
    
    .primary__btn,
    .secondary__btn{
        font-size: 0.6rem; /* Increased the font size */
        font-weight: 700; /* Lighter font weight */
        white-space: nowrap; /* Keeps text on a single line */

    } 
}

@media only screen and (max-width:380px){
    .hero__content h3 {
        font-size: 0.6rem; /* Decreased the font size */
        margin-top: 10px; /* Adjusted top margin */
    } 
    
    .hero__content h2{
        font-size: 0.5rem; /* Decreased the font size */
        line-height: 1.0; /* Adjusted line-height */
        margin-bottom: 10px; /* Adjusted bottom margin */
    }

    .hero__img {
        height: 120px; /* Adjusted height */
        margin-top: 80px; /* Adjusted top margin */
        margin-left: 10px; /* Adjusted left margin */
    }

    .primary__btn,
    .secondary__btn {
        font-size: 0.5rem; /* Decreased the font size */
        font-weight: 300; /* Lighter font weight */
        white-space: nowrap; /* Keeps the text on a single line */
    }
}

/* @media only screen and (min-width: 481px) and (max-width: 800px) {

    .container{
        width: 100%;
        height: auto;
    }
    
    .hero__content h3 {
        font-size: 0.78rem;
        margin-top: 20px;
    } 
    
    .hero__content h2{
        font-size: 0.7rem;
        line-height: 2px;
        margin-bottom: 20px;

    }

     .hero__btns{
        column-gap: 0.1rem;
        margin-top: -30px;
        
    }

    
     .hero__img{
        height: 180px;
        width: 220px;        
        margin-top: 70px;
    }
    
    .primary__btn,
    .secondary__btn{
        padding: 0.1rem 0.7rem;
        font-size: 8px;
    } 
} */

/* @media only screen and (orientation: landscape) {
    /* Styles for landscape (horizontal) orientation go here */

    /* .hero__content {
        width: 70%;
    }
    
    .hero__img {
        width: 100%; 
    }

    .hero__content h2 {
        font-size: 1.8rem;
        line-height: 2rem;
    }
    
    .hero__content h3 {
        font-size: 1.2rem;
    } 
    
    .hero__content p {
        font-size: 1rem;
    }

    .hero__btns {
        column-gap: 1.4rem;
    }

    
    
    .primary__btn, .secondary__btn {
        padding: 0.4rem 1.2rem;
    } */
