
.slider__content-top{
    margin-top: 150px;
    text-align: center;
    margin-bottom: 50px;
}

.slider__wrapper{
    
    width: 70%;
    margin: auto;
}

.slider__item {
    background: var(--testimonial-bg);
    padding: 40px 30px;
    border-radius: .0.5rem;
    text-align: center;
    cursor: pointer;
}


.customer__details {
    
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    margin-top: 40px;
}

.customer__name {
    font-size: 1.2rem;
    color: rgb(5, 0, 0);
    font-weight: 500;
}

@media only screen and (max-width:810px){
    .slider__wrapper{
    
        width: 100%;
        margin: auto;
    }
}

@media only screen and (max-width:480px){
    
    .slider__wrapper{
        width: 100%;
        margin: auto;
    }
    h2{
        font-size: 1.2rem;
    }
    .description {
        font-size: 0.8rem;
        
    }
    .slider-image {
        width: 100%; /* Adjust this value if you want a different width */
        height: auto; /* Maintain the aspect ratio of the image */
    }
    .slider-container {
        margin-top: -500px; /* Adjust this value to change the distance from the top */
      }
}