.blog-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  margin-top: 120px;
  margin-bottom: 120px;
  margin-left: 250px;
  width: 1000px;
}

.article-card {
  background-color: white;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.08);
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 25px;
  text-align: left;
  color: #4B4F56;
  width: 100%;
  max-width: 300px;
  white-space: normal;
  transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
  cursor: pointer;
  position: relative; /* Add position relative to the parent container */
}

.article-card h2 {
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.article-card p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.article-card p em { /* Style the italic date */
  font-style: italic;
}

.article-card button {
  background-color:  #140c34;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.article-card button:hover {
  background-color: rgb(84,196,148);;
}

/* Responsive styles */

/* For screen sizes up to 1150px (similar to .pageDesc) */
@media only screen and (max-width: 1150px) {
  .blog-container {
    margin-left: 50px; /* Adjust the margin-left for smaller screens */
  }

  .article-card {
    max-width: 250px; /* Decrease the max-width for smaller screens */
  }
}

/* For screen sizes up to 768px (similar to .courses__container) */
@media only screen and (max-width: 768px) {
  .blog-container {
    grid-template-columns: repeat(2, 1fr); /* Adjust the grid layout for smaller screens */
    width: 90%; /* Use a percentage width for better responsiveness */
    margin-left: 10px; /* Adjust the margin-left for smaller screens */
  }

  .article-card {
    max-width: 100%; /* Allow the card to take the full width on smaller screens */
  }
}

/* For screen sizes up to 480px (similar to the last media query in training.css) */
@media only screen and (max-width: 480px) {
  .blog-container {
    grid-template-columns: repeat(1, 1fr); /* Adjust the grid layout for even smaller screens */
    width: 90%; /* Use a percentage width for better responsiveness */
    margin-left: 10px; /* Adjust the margin-left for smaller screens */
  }

  .article-card {
    max-width: 100%; /* Allow the card to take the full width on smaller screens */
  }

  /* Add any additional styles for smaller screens as needed */
}
