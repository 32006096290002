
.header{
    width: 100%;
    height: 80px;
    line-height: 80px;
    background-color: aliceblue;
}

.nav__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}

.menu{
    display: flex;
    align-items: center;
    position: absolute;
    top: 0rem;
    left: 30rem;
    column-gap: 2.7rem;
    list-style: none;
    
}

.menu__link{
    font-weight: 400;
    font-size: 0.9rem;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-weight:bold;
    color: rgb(8, 0, 0);
    cursor: pointer;
}

.menu__link:hover{
    color: var(--link-active);
}

.logo h2 {
    color: var(--primary-color);
}

.light__mode span{
    color: rgba(3, 3, 3, 0.637);
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    font-size: 0.8rem;
    cursor: pointer;

}

.header__shrink{
    width: 100%;
    height: 80px;
    line-height: 80px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9999;
}

.image {
    position: absolute;
    top: -0.5rem;
    left: 8rem;
    border-radius: 100rem;
   
  }


.mobile_menu{
    color: #030000;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
    
    
}


.light-theme .menu__link{
    font-weight: bold;
}

.light-theme .mobile_menu{
    color: #000;
     
}

/* responsive */

@media only screen and (max-width:992px){
    .header {
        width: 100%;
        height: 50px;
        line-height: 50px;
    }
    
    .navigation{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(6, 11, 26, 0.868);
        z-index: 99999;
        display: none;

    }

    
    .menu {
        flex-direction: column;
        background: #0c123d;
        width: 55%;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999999;

    } 

    .menu .menu__link{
        color: #fff;
    }
    
    .menu__active {
        display: block;
    }

    .mobile_menu{
        display: block;
    }
    
}


@media only screen and (max-width:1140px){
    .container{
      width: 950px;
      margin-top: 20px;
    }
    
    .navigation{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(6, 11, 26, 0.868);
        z-index: 99999;
        display: none;
    }

    
    .menu {
        flex-direction: column;
        background: #0c123d;
        width: 35%;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999999;
        margin-left: 10px;
    } 

    .menu .menu__link{
        color: #fff;
    }
    
    .menu__active {
        display: block;
    }

    .mobile_menu{
        display: block;
    }
    
  }


  @media only screen and (max-width:768px){
    .header {
        width: 100%;
        height: 50px;
        line-height: 50px;
    }

  }



@media only screen and (max-width:480px){
    .container {
        width: 100%; /* Adjust the container width for smaller screens */
        margin-top: 20px;
      }
    
      .header {
        width: 100%;
        height: 50px;
        line-height: 50px;
      }

      .navigation{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(6, 11, 26, 0.868);
        z-index: 99999;
        display: none;
    }
    .menu {
        flex-direction: column;
        background: #0c123d;
        width: 100%; /* Adjust the menu width for smaller screens */
        height: 100%;
        align-items: flex-start; /* Align the items to the left */
        justify-content: center;
        position: absolute;
        top: -100px;
        right: 10;
        left: -10px;
        z-index: 99999;
        padding-left: 100px; /* Add some padding to create space between the text and the left edge */
      }
    
      .menu .menu__link {
        color: #fff;
      }
    
      .menu__active {
        display: block;
      }
    
      .mobile_menu {
        display: block;
      }
 
    

}


