.casestudies__top-content{
    text-align: center;
    margin-top: 100px;
}

.casestudies__item-wrapper, .casestudies__item-wrapper2{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* allows items to wrap onto the next line if there isn't enough space */
    gap: 2rem;
    
    
}

.casestudies__item{
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers children along the cross-axis (horizontal axis in this case) */
    justify-content: center; /* Centers children along the main axis (vertical axis in this case) */
    background: var(--card-bg);
    flex: 0 1 calc(50% - 2rem); /* Each item will take half the width, minus the gap */
    padding: 35px;
    text-align: center;
    border-radius: 0.5rem;
    cursor: pointer;
}

.casestudies__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    width: 50px;
    height: 50px;
    margin-bottom: 30px;
    border: 1px solid var(--primary-color);
    background: var(--icon-bg);
    border-radius: 5px;
}

.light-theme .service__icon {
    background-color: var(--primary-color);
}

.light-theme .service__icon i{
    color: #fff;
}

.casestudies__icon i{
    font-size: 2rem;
    color: var(--primary-color);
}

.casestudies__title{
    text-align: center;
    color: var(--primary-color);
    font-size: 1rem;
    font-weight:500; /* This makes the font bold */
    margin-bottom: 10px;
    width: 90%;
    word-break: normal; /* Default value, break at the end of the line after a word */

}

.light-theme .casestudies__item{
    background:  aliceblue;
}


@media only screen and (max-width:480px){
    .container{
        width: 100%;
    }
    .subtitle{
        font-size: 1rem;
    }
    .casestudies__item{
        width: 100%;
    }
    .description__about__content-desc{
        font-size: 0.57rem;
        display: flex;
        margin-bottom: -20px;
    }
    .choose_us-title {
        margin-top: 30px;
        color: #000000;
        font-weight: 500;
        font-size: 1rem;
        font-weight: bolder;
    }
    p{
        font-size: 1rem;
        
    }
    
    .about_learn_button{
        margin-top: 10px;
    }

    .about_btn{
        font-size: 0.57rem;
    }
    
    .about__img {
       height: 510px;
    }
    
   
    
    .choose__us-icon{
        margin-top: 25px;
    }
    
    .choose_us-title {
        color: #000000;
        font-weight: 500;
        font-size: 0.8rem;
        width: 300%;
    }
    
    .description__about{
        margin-top: 10px;
        color: #050101;
        font-size: 0.57rem;
        width: 250%;
    }
    .casestudies__title{
        font-size: 0.85rem;
        width: 100%;
    }
    
    .casestudies__item-wrapper,  .casestudies__item-wrapper2{
        flex-direction: column;
        align-items: center;
    }
    
}
    
   