.about__wrapper{
    display: flex;
    column-gap: 2rem;
    margin-top: 100px;
}

.about__content,.about__img{
    width: 1000px;
}

.lister{
    margin-inline: 30px;
    font-size: 1rem;
}

.para-height {
    line-height: 1.8;
    text-align: justify;
}

.subtitle_services_1{
    font-size: 1rem;
} 

.about__content_services {
    width: 6000px;
}

.subtitle_services{
    font-size: 1.6rem;
    color: var(--primary-color);

}

.subheaderTrain{
    margin-top: 150px;
    font-size: 2rem;    
}
.about__img img{
    width: 950px;
    height: 300px;
    margin-left: 0px;
    object-fit:fill;
    border-radius: 1.5rem;
    margin-bottom: 25px;
    
}

.subheader {
    font-size: 1rem;
    margin-bottom: -20px;
      
}


.lists {
    margin-inline: 10px;    
    font-size: 0.9rem;
    font-weight: normal;
    margin-inline: 25px;
    margin-top: 10px;
    
}

.lists_services {
    margin-inline: 10px;    
    font-size: 1rem;
    font-weight: normal;
    margin-inline: 25px;
    margin-top: 10px;
    
    
}
.about__content-desc{
    font-size: 1rem;
    font-weight: normal;
    margin-inline: 10px;
    text-align: justify;

    max-width: 900px; /* You can adjust this value to fit your needs */
}

.about__content-desc_train{
    font-size: 1rem;
    font-weight: normal;
    margin-inline: 100px;
    max-width: 900px; /* You can adjust this value to fit your needs */
    text-align: justify;

}

.choose__item-wrapper{
    margin-top: 20px;
}

.choose__us-item{
    padding: 20px 20px 20px 0px;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 15px;
}

.choose__us-icon{
    width: 55px;
    height: 110px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
}

.choose__us-icon i{
    color: var(--primary-color);
    font-size: 3rem;
}

.choose__us-title {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
}

.description_about{
    margin-top: 10px;
    color: #fff
}



.about_btn{
    padding: 0.1rem 1rem;
    outline: none;
    color: #fff;
    border-radius: 0.9rem;
    cursor: pointer;
    font-weight:bold;
    font-family:Georgia, 'Times New Roman', Times, serif;
}

.about_btn{
    background: var(--btn-primary-bg);
    border: 1px solid var(--btn-primary-bg);
}




@media only screen and (max-width:810px){
    .project__title{
     font-size: 0.7rem;
    }
 
    .projects__icon i{
     font-size: 1.5rem;
 
     }
 
     .projects__icon{
         width: 40px;
         height: 40px;
         border-radius: 50px;
         margin-bottom: 10px;
     }
 
     .project__item-wrapper{
         margin-top: 10px;
         justify-content:space-around;
         column-gap: 2rem;
     }
 
     .projects__item{
         margin-top: 30px;
         padding: 15px;
         text-align: center;
         border-radius: 0.5rem;
         cursor: pointer;
     }
 }
 
 
 
@media only screen and (max-width:480px)
{   
    .para-height {
        text-align:left;
    }
    .about__content,.about__img{
        width: 100px;
    }
        
    .about__img img{
            width: 330px;
            height: 200px;
            margin-left: 0px;
            object-fit:fill;
            border-radius: 1.5rem;
            margin-top: 15px;
        }

    .about__img_1{
            width: 330px;
            height: 200px;
            margin-left: 0px;
            object-fit:fill;
            border-radius: 1.5rem;
            margin-top: 250px;
    } 

    .subtitle_services{
            font-size: 1rem;
            color: var(--primary-color);
            max-width: 350px;
            margin-top: -40px;
        }
        
    .subtitle_services_1{
            font-size: 1rem;
            max-width: 350px;
            margin-top: -40px;
    }    
     .container{
         width: 100%;
     }

     .subtitle{
         font-size: 1rem;
     }

     .about__content-desc_train{
        font-size: 1rem;
        font-weight: normal;
        margin-inline: 30px;
        max-width: 320px; /* You can adjust this value to fit your needs */
        text-align:left;
    }

    .about__content-desc{
        font-size: 1rem;
        font-weight: normal;
        margin-inline: 10px;
        max-width: 320px; /* You can adjust this value to fit your needs */
        text-align:left;

    }

     .choose_us-title {
         margin-top: 30px;
         color: #000000;
         font-weight: 500;
         font-size: 1rem;
         font-weight: bolder;  
     }
     
     p{
         font-size: 1rem;
         
     }
     
     .about_learn_button{
         margin-top: 10px;
     }
 
     .about_btn{
         font-size: 0.57rem;
     }
     
     
     .choose__us-icon{
         margin-top: 25px;
     }
     
     .choose_us-title {
         color: #000000;
         font-weight: 500;
         font-size: 0.8rem;
         width: 300%;
     }
     
     .description__about{
         margin-top: 10px;
         color: #050101;
         font-size: 0.57rem;
         width: 250%;
     }

     .subheaderTrain{
        margin-top: 150px;
        font-size: 1rem;    
    }

  
     
 }
     
     