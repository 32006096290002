.contact-container {
    margin: 0 auto;
    width: 1100px;
    padding: 20px;
    background-color:  rgb(24, 65, 122);
    border-radius: 10px;
    margin-left: -20px;
    margin-top: 50px;
  }
  
  .contact-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .contact-form input,
  .contact-form textarea {
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 5px;
    resize: none;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .contact-form button {
    padding: 10px;
    width: 100px;
    border-radius: 5px;
    background-color: #4CAF50;
    color: #fff;
    font-weight: bold;
    border: none;
    cursor: pointer;
    margin-left: 500px;
  }

  @media only screen and (max-width:810px){
    .contact-container {
      width: 100%;
      margin-left: 0px;
    }

    .contact-form button {
      margin-left: 300px;
    }
  }

  @media only screen and (max-width:480px){
    .contact-container {
      width: 100%;
      margin-left: 0px;
    }

    .contact-form button {
      margin-left: 120px;
    }
  }