.courses {
    margin-top: 10rem;
}

.pageDesc {
    display: flex;
    text-align:justify;
    margin-top: 10px;
    font-size: 1rem;
    width: 1150px;
    margin-left: 2600px;
    margin-bottom: 10px;
}

.page{
    display: flex;
    margin-top: 80px;
}

.courses__container {
    display:grid;
    grid-template-columns: repeat(2, 3fr);
    gap: 4rem;
    margin-top: 60px;
}

@keyframes bounce {
    0%   { transform: scale(1); }
    50%  { transform: scale(1.1); }
    100% { transform: scale(1); }
}
.course {
    transition: box-shadow 0.1s ease;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    cursor: pointer;
}
.course:hover{
    animation: bounce 1.5s ease;
    box-shadow: 0 0 15px rgba(1, 146, 1, 0.7);
}
.subtitle_train{
    margin-bottom: 30px;
    text-align: center;
    margin-top: -50px;
    align-content: center;
    font-size: 2rem;
    color: var(--primary-color);
    font-weight: 400;
  }

.course__info {
    padding: 2rem;
}

.course__info p{
    margin: 1.2rem 0 2rem;
    font-size: 0.9rem;
}


@media only screen and (max-width:480px){
    .courses__container{
        display:grid;
        grid-template-columns: repeat(1, 3fr);
        gap: 5rem;
        font-size: 0.9rem;
    }

    .courses__container p{
        font-size: 0.9rem;
    }


    .about__content-desc{
        font-size: 0.7rem;
        margin-inline: 10px;
    }

    .subtitle_train{
        font-size: 1.2rem;
        font-weight: 400;
    }
}