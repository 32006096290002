.our__team {
    padding-top: 60px;
}

.team__content{
    text-align: center;
    margin-bottom: 50px;
}

.team__item{
    width: 20%;
    background: var(--team-card-bg);
    border-radius: 5px;
}

.team__img{
    width: 100%;
    height: 200%;
    border-radius: 5px;
    background: white;
    overflow: hidden;
    padding-top: 25px;
}

.team__img img{
    width: 100%;
    height: 180px;
    object-fit: contain;
    transform: scale(1.3);
}

.team__details{
    padding: 20px 15px;
}

.team__details h4{
    color: var(--primary-color);
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 0.4rem;
    margin-left: -10px;
}

.team__details p{
    font-weight: 500;
    margin-left: -5px;
    font-size: 0.9rem;
}

.team__member-social{
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 10px;
    margin-left: -5px;

}

.team__member-social span{
    padding: 5px;
    background: var(--icon-bg);
    color: white;
    border-radius: 3px;
    cursor: pointer;
}

.team__wrapper {
    display: flex;
    align-items: center;
    column-gap: 19.8rem;
    margin-left: 220px;

}


@media only screen and (max-width:810px){
    .team__wrapper {
        display: flex;
        align-items: center;
        column-gap: 18.5rem;
        margin-left: 90px;
        padding: 30px;
    }
    .team__details{
        padding: 0px 0px;
    
    }
    .team__details{
        padding: 0px 0px;
        margin-top: 5px;
    }
   
    
    .team__img{
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background:white;
        padding: -250px;
    }
    .team__img img{
        width: 100%;
        height: 180px;
        margin-top: -35px;
        object-fit: contain;
        transform: scale(1.3);
    }
    .team__details h4{
        color: var(--primary-color);
        font-weight: 1000;
        font-size: 0.8rem;
    }
    
    .team__details p{
        font-weight: 500;
        font-size: 0.75rem;
    }

    .team__member-social{
        column-gap: 0.5rem;
        margin-top: 4px;
    }

    .team__member-social span{
        padding: 2px;
       
    }

    
}

@media only screen and (max-width:480px){
    
    .team__wrapper {
        display: flex;
        justify-content: space-between;
    }
    
    .team__item{
        background: var(--team-card-bg);
        border-radius: 10px;
        margin-left: -120px;


    }
    
    .team__details{
        margin-top: 0px;
        width: 70px;
        margin-left: 10px;
    }
    
    .team__img{
        width: 60px;
        background:white;
        padding: -20px;
        margin-bottom: 0px;

    }
    .team__img img{
        width: 100%;
        height: 100px;
        object-fit: contain;
        transform: scale(1.3);
        margin-top: -37px;
    }
    .team__details h4{
        color: var(--primary-color);
        font-weight: 500;
        font-size: 0.5rem;
        margin-top: -10px;
        
    }
    
    .team__details p{
        font-weight: 500;
        font-size: 0.45rem;
        margin-top: -5px;

    }

    .team__member-social{
        column-gap: 0.1rem;
        margin-top: 0px;
    }

    .team__member-social span{
        padding: 0px;
        font-size: 0.9rem;
        margin-left: 0px;
        margin-bottom: 25px;
    }
}

@media only screen and (max-width:480px){
    
    .team__wrapper {
        display: flex;
        justify-content: space-between;
    }
    
    .team__item{
        background: var(--team-card-bg);
        border-radius: 10px;
        margin-left: -120px;


    }
    
    .team__details{
        margin-top: 0px;
        width: 70px;
        margin-left: 10px;
    }
    
    .team__img{
        width: 60px;
        background:white;
        padding: -20px;
        margin-bottom: 0px;

    }
    .team__img img{
        width: 100%;
        height: 100px;
        object-fit: contain;
        transform: scale(1.3);
        margin-top: -37px;
    }
    .team__details h4{
        color: var(--primary-color);
        font-weight: 500;
        font-size: 0.5rem;
        margin-top: -10px;
        
    }
    
    .team__details p{
        font-weight: 500;
        font-size: 0.45rem;
        margin-top: -5px;

    }

    .team__member-social{
        column-gap: 0.1rem;
        margin-top: 0px;
    }

    .team__member-social span{
        padding: 0px;
        font-size: 0.9rem;
        margin-left: 0px;
        margin-bottom: 25px;
    }
}


@media only screen and (min-width: 481px) and (max-width: 800px) {
    .team__wrapper {
        display: flex;
        justify-content: space-between;
    }
    
    .team__item{
        background: var(--team-card-bg);
        border-radius: 10px;
        margin-left: -120px;
    }
    
    .team__details{
        margin-top: 0px;
        width: 70px;
        margin-left: 10px;
    }
    
    .team__img{
        width: 60px;
        background:white;
        padding: -20px;
        margin-bottom: 0px;

    }
    .team__img img{
        width: 100%;
        height: 100px;
        object-fit: contain;
        transform: scale(1.3);
        margin-top: -37px;
    }
    .team__details h4{
        color: var(--primary-color);
        font-weight: 500;
        font-size: 0.5rem;
        margin-top: -10px;
        
    }
    
    .team__details p{
        font-weight: 500;
        font-size: 0.45rem;
        margin-top: -5px;

    }

    .team__member-social{
        column-gap: 0.1rem;
        margin-top: 0px;
    }

    .team__member-social span{
        padding: 0px;
        font-size: 0.9rem;
        margin-left: 0px;
        margin-bottom: 25px;
    }
}