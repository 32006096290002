/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

/* css variables */
:root {
  --body-bg:  aliceblue;
  --primary-color: rgb(84,196,148);
  --heading-color: #140c34;
  --btn-primary-bg: rgb(84,196,148);
  --btn-secondary-bg: rgb(84,196,148);
  --team-card-bg: aliceblue;
  --link-color: #0c123d;
  --link-active: #816aff
  --card-bg: linear-gradient(
    45deg,
    rgba(15, 18, 61, 1) 33%,
    rgba(15, 18, 61, 1) 52%,
    rgba(11, 41, 69, 1) 84%
  );
  --counter-color: #fff;
  --counter-section-bg: rgb(84,196,148);

  --newsletter-bg: linear-gradient(
    180deg,
    rgba(189, 243, 255, 1) 29%,
    rgba(193, 243, 255, 1) 50%,
    rgba(226, 250, 255, 1) 78%
  );
  --testimonial-bg: #f7f7f7;
  --font-name: "Roboto", sans-serif;
  --small-text-color:#000;
  --icon-bg:#151e4f;
}

.light-theme {
  --body-bg:  aliceblue;
  --heading-color: #140c34;
  --btn-secondary-bg: rgb(84,196,148);
  --btn-primary-bg: #fff;
  --primary-color: rgb(84,196,148);
  --counter-color: #fff;
  --counter-section-bg: rgb(84,196,148);
  --link-color: #0c123d;
  --link-active: #816aff
  --newsletter-bg: linear-gradient(
    180deg,
    rgba(189, 243, 255, 1) 29%,
    rgba(193, 243, 255, 1) 50%,
    rgba(226, 250, 255, 1) 78%
  );
  --small-text-color:#000;
  --card-bg:#fff;
  --testimonial-bg: #f7f7f7;
  --team-card-bg: #fff;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}


body{
    background: var(--body-bg);
    font-family: var(--font-name);
}

a{
    text-decoration: none;
    color: var(--link-color);
}

h1,h2{
    color: var(--heading-color);
    font-size: 2rem;

}


.container{
    width: 1140px;
    padding: 0px 15px;
    margin:auto
}

.description{
  color: var(--small-text-color);
  line-height: 30px;
  font-size: 0.9rem;
}

.subtitle{
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: 400;
}

.counter{
  background: var(--counter-section-bg);
}

.secondary__btn{
  border: 1px solid var(--primary-color);
  color: #090238;
  font-weight: 600;
}

a{
  font-weight: 500;
}

.team__img{
  background: #ddd;
}

.team__img{
  background: #ddd;
}

.choose__us-title{
  color: #000;
}

.description_about{
  color: #000;
}

.about_btn{
  background: var(--btn-secondary-bg);
  color: rgb(251, 253, 252);
}

.customer__name{
  color: #000;
}

