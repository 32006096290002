.footer{
    width: 100%;
}

.footer__wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}

.footer__logo{
    width: 40%;
}

.footer__quick-links {
    width: 20%;
}

.footer__logo h2{
    color: var(--primary-color);
}

.small__text {
    font-size: 1rem;
    margin-top: 20px;
}

.quick__link-title {
    font-size: 1.1rem;
    color: var(--primary-color);
    font-weight: 400;
    margin-bottom: 20px;
}

.quick__links{
    list-style: none;
}

.quick__link-item{
    margin-bottom: 10px;
}

.quick__link-item a{
    font-size: 0.9rem;
    font-weight: 300;
}

.quick__link-item a:hover{
    color: var(--primary-color);
}

.quick__link-item-social{
    display: flex;
    font-size: 250%;
    cursor: pointer;
}

@media only screen and (max-width:810px){
    
    .footer{
        width: 100%;
        
    }
    .footer__quick-links {
        width: 30%;
    }
}

@media only screen and (max-width:480px){
    .footer{
        width: 100%;
    }
    .footer__quick-links {
        width: 40%;
    }
    
    .small__text {
        font-size: 0.5rem;
        margin-top: 5px;
        font-weight: 400;

    }
    .quick__link-item a{
        font-size: 0.6rem;
        font-weight: 400;
    }
    .quick__link-title {
        font-size: 0.8rem;
        color: var(--primary-color);
        font-weight: 500;
        margin-bottom: 5px;
    }
    .quick__link-item{
        margin-bottom: 5px;
    }
    .footer__logo h2{
        font-size: 0.9rem;
    }
    .quick__link-item-social{
        font-size: 200%;
    }
}